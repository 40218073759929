<template>
    <v-dialog persistent v-model="isVisible" no-click-animation :transition="false" :content-class="'sidebar-right' + (isSliding == 'in' ? ' sidebar-right-slide-in' : '') + (isSliding == 'out' ? ' sidebar-right-slide-out' : '') + (isSidebarScrollable ? ' sidebar-right-scrollable' : '')" @keydown.enter.prevent="actionSave()" @click:outside="actionHide()">
        <v-card tile elevation="0" class="detect-scrollable" v-on-escape="{ condition: isVisible, action: actionHide }">
            <v-card-title class="mr-3 mt-3 pb-0">
                <h1>{{ form.id ? 'Edit project' : 'New project' }}</h1>
                <v-spacer></v-spacer>
                <v-icon class="float-right" @click="actionHide()">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle class="pt-5" v-if="activeTeam">
                {{ activeTeam.name || meData.name }}
            </v-card-subtitle>
            <v-card-text>
                <v-form v-model="form.isValid" ref="form" @submit.prevent>
                    <nodeupError :options="nodeupErrorOptions" />

                    <v-text-field variant="underlined" flat class="pt-10" autofocus persistent-placeholder :rules="formRules.name" v-model="form.name" label="PROJECT NAME" :error-messages="store.sidebarInlineErrors.name" @input="$clearSidebarInlineError('name')"></v-text-field>

                    <v-text-field variant="underlined" flat class="pt-10" persistent-placeholder :rules="formRules.description" v-model="form.description" label="PROJECT DESCRIPTION (OPTIONAL)" persistent-counter counter="300"></v-text-field>

                    <div class="v-field v-field--variant-underlined mt-4">
                        <div class="v-field__field">
                            <label class="v-label v-field-label">CHOOSE MARKER COLOR</label>
                            <div class="mt-9">
                                <v-chip @click="actionChipActive(form, 'color', key)" :class="form.color == key ? 'v-chip--active' : ''" v-for="key in form.colors" :key="key" :style="{ 'background-color': key }">
                                    <v-icon v-if="form.color == key">$tickblue</v-icon>
                                </v-chip>
                            </div>
                        </div>
                    </div>

                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn size="large" color="primary-green" @click="actionSave()" :disabled="isSubmitting">Save Project</v-btn>
                <v-btn size="large" color="light-grey" @click="actionHide()">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import { useNuxtApp } from '#app';
import { useRepo } from 'pinia-orm';
import { useStore } from '@/store/index';
import Me from '@/data/models/Me';
import Project from '@/data/models/Project'
import Team from '@/data/models/Team'
import nodeupError from '~/components/nodeupError'

export default {
    components: {
        nodeupError
    },
    data() {
        return {
            isVisible: false, // sidebar is visible
            isSliding: null, // 'in' or 'out'
            isSubmitting: false, // double click protection
            isSidebarScrollable: false,

            nodeupErrorOptions: {
                type: 'sidebar',
            },

            formRules: {
                name: [
                    v => !!v || 'Project name is required!',
                ],
            },

            // form variables are overridable by parent
            form: {
                isValid: null,

                colors: ['#00C524', '#00AAFF', '#F85D55', '#F7B466', '#175CFF', '#B055F8', '#007616', '#DD7700', '#FF9792', '#6A00BE', '#66686D', '#002E9A'],

                id: null, // only needed for edit
                name: '',
                description: '',
                color: '#00C524',
            },
        }
    },
    watch: {
        async isVisible() {
            // add scrollable class to v-dialog
            const nuxtApp = useNuxtApp();
            this.isSidebarScrollable = await nuxtApp.$isElementScrollable('detect-scrollable')
        }
    },
    created() {
        // save initial empty form for later use
        // deep copy to avoid linked values
        // rules should be outside this.form!
        this.initialForm = JSON.parse(JSON.stringify(this.form))
    },
    computed: {
        activeTeamId() {
            const store = useStore();
            return store.team;
        },
        meData() {
            return Me.getData()
        },
        activeTeam() {
            const store = useStore();
            return useRepo(Team).find(store.team)
        },
        store() {
            const store = useStore();
            return store
        },
    },
    methods: {
        actionShow(formData) {
            this.clearFormInvalid()

            // extend form parameters by parent method
            this.form = { ...JSON.parse(JSON.stringify(this.initialForm)), ...formData }

            this.isSliding = 'in'
            this.isVisible = true
        },
        actionHide() {
            this.isSliding = 'out'
            const context = this
            setTimeout(() => context.isVisible = false, 300)
        },
        actionChipActive(el, key, val) {
            el[key] = val
        },
        async actionSave() {
            const store = useStore();
            const { valid } = await this.$refs.form.validate()
            this.form.isValid = valid

            if (this.form.isValid && !this.isSubmitting) {
                this.isSubmitting = true

                var variables = {
                    project: {
                        name: this.form.name,
                        color: this.form.color,
                        description: this.form.description,
                    }
                }

                // update
                if (this.form.id) {
                    variables.id = this.form.id
                    var res = await Project.apiUpdate(variables)
                    if (res && res.updateProject) {
                        this.$setToastMessage({ message: 'Project updated.' })
                    }

                    // create
                } else {
                    variables.project.team = {
                        id: parseInt(this.activeTeamId)
                    }
                    var res = await Project.apiInsert(variables)
                    if (res && res.createProject) {
                        this.$setToastMessage({ message: 'Project created successfully.' })
                    }
                }

                this.isSubmitting = false

                if (res && !res.errors) {

                    if (res.createProject && res.createProject.id) {
                        store.setProject(res.createProject.id)
                        // go to project dashboard
                        this.$router.push('/projects/' + res.createProject.id)
                    }

                    this.actionHide()
                }
            }
        },
        clearFormInvalid() {
            const nuxtApp = useNuxtApp();
            nuxtApp.$clearSidebarErrors()
            return nuxtApp.$runWhenRefAvailable({ context: this, ref: 'form', method: 'resetValidation' })
        },
    }
}
</script>

<style scoped lang="scss">
.v-chip {
    width: 32px;
    height: 32px;
    margin-right: 7px;
    min-width: unset;
}

:deep(.v-chip--active) {
    border: 2px solid #000 !important;
    box-shadow: inset 0px 0px 0px 1px #FFF;

    .v-icon {
        font-size: 14px;
        margin-left: -10px;

        path {
            stroke: #fff;
        }
    }
}
</style>
